<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right mb-2">
            <b-button variant="info" class="mr-2" v-b-modal.add-call-record>
              <i class="fa fa-plus"></i>
            </b-button>

            <button
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table id="calls_and_reports" class="table table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th>Elderly Name</th>
                    <th>Elderly Phone</th>
                    <th>Sponsor</th>
                    <th>Sponsor Phone</th>
                    <th>Invoice</th>
                    <th>Invoice Status</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Update</th>
                    <th>Created On</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(log, index) in logs" :key="log.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ log.prescription.elderly_name }}</td>
                    <td>{{ log.prescription.elderly_phone }}</td>
                    <td>{{ log.prescription.sponsor_name }}</td>
                    <td>{{ log.prescription.sponsor_phone }}</td>
                    <td>{{ log.prescription.invoice.invoice_no }}</td>
                    <td>
                      <b-badge
                        variant="success"
                        class="p-2"
                        v-if="log.prescription.status == 'paid and delivered'"
                      >
                        Paid and Delivered
                      </b-badge>
                      <b-badge
                        variant="info"
                        class="p-2"
                        v-if="log.prescription.status == 'paid not delivered'"
                      >
                        Paid and not delivered
                      </b-badge>
                      <b-badge
                        variant="outline-success"
                        class="p-2"
                        v-if="
                          log.prescription.status == 'not paid but delivered'
                        "
                      >
                        Not paid but delivered
                      </b-badge>
                      <b-badge
                        variant="danger"
                        class="p-2"
                        v-if="
                          log.prescription.status == 'not paid not delivered'
                        "
                      >
                        Not paid not delivered
                      </b-badge>
                    </td>
                    <td>{{ formatMoney(log.prescription.overall_total) }}</td>
                    <td>
                      <b-badge
                        v-if="log.status == 'proceed'"
                        variant="success"
                        class="p-2"
                      >
                        Proceed
                      </b-badge>
                      <b-badge
                        v-if="log.status == 'proceedpayfromwallet'"
                        variant="success"
                        class="p-2"
                      >
                        Proceed & Pay From Wallet
                      </b-badge>
                      <b-badge
                        v-if="log.status == 'sponsor will handle'"
                        variant="info"
                        class="p-2"
                      >
                        Sponsor will handle
                      </b-badge>
                      <b-badge
                        v-if="log.status == 'pending'"
                        variant="dark"
                        class="p-2"
                      >
                        Pending reply
                      </b-badge>
                      <b-badge
                        v-if="log.status == 'declined'"
                        variant="danger"
                        class="p-2"
                      >
                        Sponsor Declined
                      </b-badge>
                      <b-badge
                        v-if="log.status == 'exceeded'"
                        variant="danger"
                        class="p-2"
                      >
                        Exceeded 48 hours
                      </b-badge>
                    </td>
                    <td>{{ log.update }}</td>
                    <td>{{ formatDateTime(log.created_at) }}</td>

                    <!-- <td>
                      <div class="btn-group">
                        <button type="button" class="btn btn-warning btn-sm" @click="startEditAction(log)"> <i class="fa fa-edit"></i> </button>
                        <button type="button" class="btn btn-danger btn-sm" @click="startDeleteAction(log.id)"> <i class="fa fa-trash"></i> </button>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Add new call record -->
      <b-modal
        id="add-call-record"
        title="Create Follow-Up Record"
        size="lg"
        centered
      >
        <div class="form-group">
          <label>Patient Name</label>
          <v-select
            v-model="selectedPatient"
            :options="patients"
            label="fullname"
            :filterable="false"
            @search="vselectSearch"
          >
            <template #no-options> type to search patients... </template>

            <template #list-header v-if="vselectSearchResults">
              <div
                class="bg-dark border-bottom p-3 d-flex justify-content-between"
              >
                <span class="small">
                  Total: {{ vselectSearchResults.total }}<br />
                  Pages: {{ vselectSearchResults.last_page }}<br />
                  Current Page: {{ vselectSearchResults.current_page }}
                </span>
                <div class="mb-2">
                  <b-btn
                    :disabled="!vselectSearchResults.prev_page_url"
                    @click="
                      vselectGoToPage(vselectSearchResults.current_page - 1)
                    "
                    variant="info"
                    class="mr-2"
                    size="sm"
                    squared
                  >
                    &laquo; Prev
                  </b-btn>
                  <b-btn
                    :disabled="!vselectSearchResults.next_page_url"
                    @click="
                      vselectGoToPage(vselectSearchResults.current_page + 1)
                    "
                    variant="success"
                    size="sm"
                    squared
                  >
                    Next &raquo;
                  </b-btn>
                </div>
              </div>
            </template>
          </v-select>
        </div>

        <div v-if="selectedPatient">
          <div class="row">
            <div class="form-group col-md-6">
              <label>Patient Phone</label>
              <input
                type="text"
                v-model="selectedPatient.phone"
                class="form-control"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label>Sponsor Name</label>
              <input
                type="text"
                v-model="selectedPatient.user.name"
                class="form-control"
                readonly
              />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-6">
              <label>Sponsor Phone</label>
              <input
                type="text"
                v-model="selectedPatient.user.phone"
                class="form-control"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label>Sponsor Email</label>
              <input
                type="text"
                v-model="selectedPatient.user.email"
                class="form-control"
                readonly
              />
            </div>
          </div>

          <div class="p-2 text-center col-12" v-if="isLoadingRecords">
            <b-card>
              <b-skeleton animation="wave" width="50%"></b-skeleton>
              <b-skeleton animation="wave" width="60%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="80%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
            </b-card>
          </div>

          <div v-else>
            <b-alert v-if="!records.length" show>
              No pending prescriptions found!
            </b-alert>

            <div v-else>
              <div class="form-group">
                <label for="record">Prescription Record</label>

                <b-alert v-if="selectedRecord && selectedPair" show>
                  <span class="alert-heading">
                    The selected prescription is a paired (couple) prescription
                    for
                    {{ selectedRecord.elderly_name }} and
                    {{ selectedPair.elderly_name }}
                  </span>
                  <hr />
                  <b-btn variant="danger" size="sm" @click="showSplitModal()">
                    <i class="fa fa-people-arrows"></i> Split Prescription
                  </b-btn>
                </b-alert>

                <v-select
                  v-model="selectedRecord"
                  :options="records"
                  label="label"
                ></v-select>
              </div>

              <div v-if="selectedRecord">
                <div class="form-group">
                  <label for="update">Update</label>
                  <textarea
                    id="update"
                    class="form-control"
                    v-model="update"
                    rows="2"
                  ></textarea>
                </div>

                <div class="form-group">
                  <div class="n-chk mt-1" v-if="selectedRecord.use_wallet">
                    <label
                      class="new-control new-radio new-radio-text radio-success"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="proceedpayfromwallet"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">
                        Proceed & Pay from wallet
                        <i class="fa fa-credit-card"></i>
                      </span>
                    </label>
                  </div>

                  <div class="n-chk mt-1">
                    <label
                      class="new-control new-radio new-radio-text radio-success"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="proceed"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-radio-content">
                        Proceed but not paid from wallet
                        <i class="fa fa-money"></i>
                      </span>
                    </label>
                  </div>

                  <div class="n-chk mt-1">
                    <label
                      class="new-control new-radio new-radio-text radio-info"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="sponsor will handle"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-radio-content">
                        Sponsor will handle it
                      </span>
                    </label>
                  </div>

                  <div class="n-chk mt-1">
                    <label
                      class="new-control new-radio new-radio-text radio-dark"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="pending"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-radio-content">Pending Reply</span>
                    </label>
                  </div>

                  <div class="n-chk mt-1">
                    <label
                      class="new-control new-radio new-radio-text radio-danger"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        v-model="status"
                        value="declined"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-radio-content">Sponsor Declined</span>
                    </label>
                  </div>

                  <div class="n-chk mt-1">
                    <label
                      class="new-control new-radio new-radio-text radio-warning"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        v-model="status"
                        value="exceeded"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-radio-content">Exceeded 48 hours</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>

          <b-button
            size="sm"
            variant="success"
            @click.prevent="saveLog()"
            :disabled="loadingSave"
            v-if="selectedRecord"
          >
            <b-spinner v-if="loadingSave" small></b-spinner>
            Save
          </b-button>
        </template>
      </b-modal>

      <!-- Edit call record -->
      <!-- <div class="modal fade" id="editRecordModal" role="dialog" aria-labelledby="editRecordModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg"  role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <div class="mr-auto">
                                <small class="modal-title" id="editRecordModalLabel">Edit Follow up Record </small>
                              </div>
                          </div>
                          <div class="modal-body">
                              <div class="row">

                                <div class="form-group col-12">
                                    <label >Patient Name</label>
                                    <input type="text" class="form-control" v-model="toEdit.prescription.elderly_name" readonly>
                                    </div>

                                 <div class="form-group col-12 col-md-6">
                                    <label for="patientPhone">Patient Phone</label>
                                    <input type="text" v-model="toEdit.prescription.elderly_phone" class="form-control" readonly id="patientPhone">
                                </div>

                                <div class="form-group col-12 col-md-6">
                                    <label for="SponsorName">Sponsor Name</label>
                                    <input type="text" v-model="toEdit.prescription.sponsor_name" class="form-control" readonly id="SponsorName">
                                </div>

                                <div class="form-group col-12 col-md-6">
                                    <label for="SponsorPhone">Sponsor Phone</label>
                                    <input type="text" v-model="toEdit.prescription.sponsor_phone" class="form-control" readonly id="SponsorPhone">
                                </div>

                                <div class="form-group col-12 col-md-6">
                                    <label for="SponsorEmail">Sponsor Email</label>
                                    <input type="text" v-model="toEdit.prescription.sponsor_email" class="form-control" readonly id="SponsorEmail">
                                </div>



                                <div class="form-group col-12">
                                    <label for="record">Drug/Prescription Record</label>
                                     <p> {{formatMoney(toEdit.prescription.overall_total)}} billed on {{formatFancyDate(toEdit.prescription.created_at)}} </p>
                                </div>



                                <div  class="form-group col-12">
                                <label for="update">Update</label>
                                <textarea class="form-control"  id="update" v-model="toEdit.update" rows="2"></textarea>
                                </div>

                                <div class="col-12 col-md-6 mt-2">
                                    <div class="n-chk">
                                        <label class="new-control new-radio new-radio-text radio-success">
                                        <input type="radio" class="new-control-input" value="proceed" v-model="toEdit.status">
                                        <span class="new-control-indicator"></span><span class="new-radio-content">Proceed</span>
                                        </label>
                                    </div>

                                    <div class="n-chk ">
                                        <label class="new-control new-radio new-radio-text radio-info">
                                        <input type="radio" class="new-control-input" value="sponsor will handle" v-model="toEdit.status">
                                        <span class="new-control-indicator"></span><span class="new-radio-content">Sponsor will handle it</span>
                                        </label>
                                    </div>

                                    <div class="n-chk ">
                                        <label class="new-control new-radio new-radio-text radio-dark">
                                        <input type="radio" class="new-control-input" value="pending" v-model="toEdit.status">
                                        <span class="new-control-indicator"></span><span class="new-radio-content">Pending Reply</span>
                                        </label>
                                    </div>



                                    <div class="n-chk">
                                        <label class="new-control new-radio new-radio-text radio-danger">
                                        <input type="radio" class="new-control-input" v-model="toEdit.status" value="declined">
                                        <span class="new-control-indicator"></span><span class="new-radio-content">No Medical Request</span>
                                        </label>
                                    </div>


                                     <div class="n-chk">
                                        <label class="new-control new-radio new-radio-text radio-danger">
                                        <input type="radio" class="new-control-input" v-model="toEdit.status" value="exceeded">
                                        <span class="new-control-indicator"></span><span class="new-radio-content">Exceeded 48 hours</span>
                                        </label>
                                    </div>
                                </div>




                              </div>
                          </div>
                          <div class="modal-footer">
                              <button class="btn  btn-dark ml-auto" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Close</button>
                              <button type="submit" class="mt-4 mb-4 btn btn-primary" @click.prevent="completeEdit()" :disabled="loadingEdit">
                                  <b-spinner small v-if="loadingEdit"></b-spinner>
                                  <span v-else>Save Changes <i class="fa fa-paper-plane"></i> </span>
                              </button>
                          </div>
                      </div>
                  </div>
            </div>  -->

      <!-- Search Modal -->
      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete  -->
      <div
        class="modal fade"
        id="deleteModal"
        role="dialog"
        aria-labelledby="editContactModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <i class="fa fa-close" data-dismiss="modal"></i>
              <div class="add-contact-box">
                <div class="add-contact-content">
                  <div class="text-center">
                    <p class="text-danger">
                      Are you sure you want to delete this Record?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">Cancel</button>

              <button
                @click.prevent="completeDelete()"
                class="btn btn-danger text-white"
                :disabled="loadingDelete"
              >
                <b-spinner small v-if="loadingDelete"></b-spinner>
                <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

export default {
  name: "Calls",

  mixins: [vselectPaginationSearch],

  data() {
    return {
      loading: true,
      loadingSave: false,
      loadingDelete: false,
      isLoadingRecords: true,

      patients: [],
      selectedPatient: null,

      records: [],
      selectedRecord: null,

      toDelete: "",

      toEdit: {
        prescription: null,
      },
      loadingEdit: false,

      update: "",
      status: "",

      search: "thismonth",
      logs: [],

      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      selectedPair: null,
    };
  },

  watch: {
    selectedPatient(val) {
      if (val) {
        this.fetchPrescriptionRecords();
      }
    },
    selectedRecord(val) {
      if (val) {
        this.selectedPair = val.paired_prescription
          ? val.paired_prescription
          : val.parent_paired_prescription;
      }
    },
  },

  mounted() {
    this.fetchRecords();
  },

  methods: {
    vselectComponentSearch(loading, vm) {
      gerohttp
        .get(geroendpoints.FETCH_PATIENTS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[full_name]": vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.patients = response.data.map((patient) => {
            patient.fullname = `${patient.firstname} ${
              patient.midname ? patient.midname : ""
            } ${patient.lastname}`;

            return patient;
          });
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },

    vselectComponentClearSelection() {
      this.patients = [];
    },

    initTable() {
      var dt = $("#calls_and_reports").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
      });
      dt.columns([5]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchRecords();
        $("#searchModal").modal("hide");
      }
    },

    fetchRecords() {
      this.loading = true;
      http
        .get(endpoints.ALL_CALLS_AND_FOLLOWUP, {
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          },
        })
        .then((response) => {
          this.logs = response;
          this.loading = false;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchPrescriptionRecords() {
      this.records = [];
      this.selectedRecord = null;

      this.isLoadingRecords = true;

      http
        .get(
          endpoints.UNPAID_RECORDS_FOR_ELDERLY.replace(
            ":id",
            this.selectedPatient.id
          )
        )
        .then((response) => {
          this.records = response.map((record) => {
            let paired_prescription = record.paired_prescription
              ? record.paired_prescription
              : record.parent_paired_prescription;

            let totalCost = paired_prescription
              ? record.overall_total +
                paired_prescription.overall_total -
                record.invoice.overall_logistics_total
              : record.overall_total;

            record.label =
              this.formatMoney(totalCost) +
              " billed on " +
              this.formatFancyDate(record.created_at) +
              (paired_prescription
                ? `... paired with ${paired_prescription.elderly_name}`
                : "");

            return record;
          });
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.isLoadingRecords = false;
        });
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    saveLog() {
      if (this.selectedPatient == null || this.selectedRecord == null) {
        this.$toast.error("Missing Input fields. Please try again.");
      } else {
        this.loadingSave = true;

        http
          .post(endpoints.NEW_CALL_AND_FOLLOW_UP, {
            prescription_id: this.selectedRecord.id,
            status: this.status,
            update: this.update,
          })
          .then((response) => {
            this.resetCallRecord();

            this.$toast.success(response);

            this.fetchRecords();

            this.$toast.info(
              "New call record is being processed in the background. Please be patient."
            );

            this.$bvModal.hide("add-call-record");
          })
          .catch((error) => {
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },

    resetCallRecord() {
      this.update = "";
      this.status = "";
      this.records = [];
      this.selectedRecord = null;
      this.selectedPatient = null;
    },

    startEditAction(x) {
      this.toEdit = x;
      $("#editRecordModal").modal("show");
    },

    completeEdit() {
      this.loadingEdit = true;
      http
        .put(endpoints.EDIT_CALL_AND_FOLLOWUP.replace(":id", this.toEdit.id), {
          status: this.toEdit.status,
          update: this.toEdit.update,
        })
        .then((response) => {
          this.$toast.success(response);
          this.loadingEdit = false;
          $("#editRecordModal").modal("hide");
          this.fetchRecords();
        })
        .catch((error) => {
          this.loadingEdit = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    startDeleteAction(x) {
      this.toDelete = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(
          endpoints.DELETE_CALL_AND_FOLLOWUP.replace(":id", this.toDelete)
        )
        .then((response) => {
          this.$toast.success(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.fetchRecords();
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    showSplitModal() {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to split the paired prescription for
        ${this.selectedRecord.elderly_name} and ${this.selectedPair.elderly_name}`,
          {
            title: "Are You Sure?",
            size: "md",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "outline-dark",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.splitPrescription(this.selectedRecord.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    splitPrescription(id) {
      http
        .patch(endpoints.SPLIT_PRESCRIPTION_INVOICE.replace(":id", id))
        .then((response) => {
          this.$toast.success(response);
          this.fetchPrescriptionRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>
